import request from "@/utils/request";

export function getMemberToken () {
    return request({
        url: `/api/rs-member-service/ucenter/login/tenant/oauth/token`,
        method: 'get'
    })
}

export function getMemberBusiness () {
    return request({
        url: `/api/rs-member-service/ucenter/login/tenant/member/business`,
        method: 'get'
    })
}
