import request from '@/utils/request'
/**
 * 文件上传前调用
 * @param data
 * @returns {AxiosPromise}
 */
export const ossStsRegister = (data) => {
  return request({
    url: '/api/huizhuyun-support/upload/resource/sts/register',
    method: 'post',
    data: data
  })
}

/**
 * 文件上传后回调
 * @param data
 * @returns {AxiosPromise}
 */
export const ossStsRegisterCallback = (data) => {
  return request({
    url: '/api/huizhuyun-support/upload/resource/sts/register/callback',
    method: 'post',
    params: {
      id: data
    }
  })
}

export const commonBusinessExport = (url, data) => {
  return request({
    url: url,
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}

/**
 * 获取业务附件列表
 * @param params 查询条件
 */
export const getAttachmentBusinessList = (params) => {
  return request({
    url: '/api/huizhuyun-management/oa-common-attachment/list/business',
    method: 'get',
    params: {
      ...params
    }
  })
}



import OSS from 'ali-oss'
/**
 * 将文件上传到阿里云OSS
 * @param {File} file - 要上传的文件对象
 * @returns {Promise} - 返回一个Promise对象，表示文件上传的过程和结果
 */
export const ossUploadFile = (file) => {
  return new Promise( async (resolve, reject) => {
    const fileMd5 = await getFileMd5(file)
    const fileExt = file.name.split('.')[1]
    let registerParams = {
      fileName: file.name,
      extName: fileExt,
      fileSize: file.size,
      hashcode: fileMd5
    }
    const registerRes = await ossStsRegister(registerParams)
    if(registerRes.code == 200) {
      const registerData = registerRes.data
      if(registerData.existed != 1) {
        //不存在时
        // oss初始化
        const client = new OSS({
          region: 'oss-cn-zhangjiakou',
          accessKeyId: registerData.accessKey,
          accessKeySecret: registerData.accessSecret,
          stsToken: registerData.accessToken,
          bucket: registerData.bucketName
        })

        const uploadRes = await client.put(registerData.resourceName, file)
        if(uploadRes.res.status != 200) reject(uploadRes);
        const result = await ossStsRegisterCallback(registerData.resourceId)
        if(result.code != 200) reject(result);
        resolve(registerData);
      } else {
        //存在时
        resolve(registerData)
      }
    } else {
      reject(registerRes)
    }
  })
}

import SparkMD5 from 'spark-md5'
const getFileMd5 = (file)=> {
  return new Promise( async (resolve, reject) => {
      // 文件读取
      var fileReader = new FileReader()
      var spark = new SparkMD5.ArrayBuffer()
      // 获取文件二进制数据
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = function (e) {
        spark.append(e.target.result)
        // 获取文件文件md5
        let md5 = spark.end()
        resolve(md5)
      }
      fileReader.onerror = function (e) {
        reject(e)
      }
  })

}