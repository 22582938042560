import request from '@/utils/request'

// export const modelList = (current, size, params) => {
//   return request({
//     url: '/api/platform/blade-flow/model/list',
//     method: 'get',
//     params: {
//       ...params,
//       current,
//       size,
//     }
//   })
// }
// 个人中心消息通知记录
export const noticeList = (params) => {
  return request({
    url: '/api/rs-cms-service/new-center-notice/list',
    method: 'get',
    params
  })
}
// 一键修全部改已读状态
export const noticeAllRead = () => {
  return request({
    url: '/api/rs-cms-service/new-center-notice/all-read',
    method: 'get'
  })
}
// 点击修改已读状态
export const noticeRead = (id) => {
  return request({
    url: `/api/rs-cms-service/new-center-notice/click-read/${id}`,
    method: 'get'
  })
}
// 未读消息数
export const getNoReadCount = () => {
  return request({
    url: `/api/rs-cms-service/new-center-notice/no-read-count`,
    method: 'get'
  })
}

//获取全部未读数量
export function getAllNoReadCount () {
  return request({
    url: '/api/rs-cms-service/new-center-notice/tab-no-read-count',
    method: 'get'
  })
}
