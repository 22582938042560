<style scoped>
.el-form-item__content {
  line-height: 25px !important;
  position: relative;
  font-size: 14px;
  margin-top: 7px;
}
</style>
<template>
  <div>
    <page-top-title name="订单详情"></page-top-title>
    <order-water :step="+orderStatus" :txtArray="txtArray" />
    <div class="box" style="display: flex; flex-wrap: wrap">
      <div class="intrudct textCss">
        <div>
          <div style="font-weight: bold; color: #409eff">详细信息</div>
          <el-form label-width="100px" :inline="false">
            <el-form-item label="收货地址：" style="margin-top: 10px">
              <div class="el-form-item__content">
                {{ form.receivingAdders.id ? form.receivingAdders.name : form.orderAddress.name }}
                {{ form.receivingAdders.id ? form.receivingAdders.contactPhone : form.orderAddress.contactPhone }}
                {{ form.receivingAdders.id ? form.receivingAdders.province : form.orderAddress.province }}
                {{ form.receivingAdders.id ? form.receivingAdders.city : form.orderAddress.city }}
                {{ form.receivingAdders.id ? form.receivingAdders.district : form.orderAddress.district }}
                {{ form.receivingAdders.id ? form.receivingAdders.address : form.orderAddress.address }}
              </div>
            </el-form-item>
            <!-- <el-form-item label="需求编号："
                                                      style="margin-top:-20px">
                                          <div class="el-form-item__content">
                                            {{ form.orderInfo.demandCode }}
                                          </div>
                                        </el-form-item> -->
            <el-form-item label="支付方式：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ orderPayMethod[form.orderInfo.payMethod] }}
                <el-button type="text" style="padding: 0"
                  v-if="form.orderInfo.payMethod == 4 && form.orderInfo.certificateStatus != 0"
                  @click="dialogLogisticVisible = true">查看凭证</el-button>
              </div>
            </el-form-item>
            <el-dialog title="汇款凭证" append-to-body :visible.sync="dialogLogisticVisible" destroy-on-close width="400px">
              <el-image v-if="form.orderInfo.certificateUrl" style=" width: 360px" :src="form.orderInfo.certificateUrl">
                <div slot="error" class="image-slotimg">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-dialog>
            <el-form-item label="支付流水号：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.payFlowCode || '-' }}
              </div>
            </el-form-item>
            <el-form-item label="支付时间：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.payDate }}
              </div>
            </el-form-item>
            <el-form-item label="创建时间：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.createTime }}
              </div>
            </el-form-item>
            <el-form-item label="订单编号：" style="margin-top: -20px">
              <div class="el-form-item__content">
                {{ form.orderInfo.orderCode }}
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="right">
        <div class="col_5">
          <div v-if="orderStatus == 1">
            <div v-if="form.orderInfo.payMethod == 4 && form.orderInfo.certificateStatus != 0">
              <div class="title">买家已提交汇款凭证，等待确认</div>
              <div class="sub-title">{{ txtArray[0] }}</div>
            </div>
            <div v-else>
              <div class="title">已下单，等待买家付款</div>
              <div class="sub-title">{{ txtArray[0] }}</div>
              <div v-if="identity==1" style="margin: 10px 0">
                您可以点击这里进行付款：<el-button type="primary" size="mini" v-if="orderStatus == 1"
                  @click="pay(formRow)">付款</el-button>
              </div>
              <div style="margin: 10px 0" v-if="lastTime > 0">
                当前订单状态为待付款，请在<span style="color: #ea5046">
                  {{ payTimeOut }} </span>内付款；若未及时付款，系统将自动取消订单。
              </div>
            </div>
          </div>
        </div>
        <template v-if="form.orderInfo.orderType != 2 || form.pinStatus == 2">
          <div style="margin-left: 10px" v-if="!isNull(form.orderLogistics)">
            <span @click="logisticsType(item.logisticsCode)" style="margin: 2px 4px; cursor: pointer"
              :class="{ tabActive: logisticsCode == item.logisticsCode }" v-for="item in form.orderLogistics"
              :key="item.id">{{ item.logisticsCode }}</span>
          </div>
          <div style="width: 100%; margin-top: 10px">
            <div style="
                width: 48%;
                float: left;
                padding-left: 10px;
                max-height: 300px;
                overflow-y: auto;
              ">
              <el-steps direction="vertical" :active="1">
                <el-step v-for="(item, index) in logistics.records" :key="index + 'tab'" :title="item.context"
                  :description="item.locationTime" icon="el-icon-help" />
              </el-steps>
            </div>
            <!--  -->
            <div v-show="!isNull(form.orderLogistics) && initMaps" style="width: 50%; float: right">
              <iframe id=“frame” :src="initMaps" style="width: 100%;border: 0px;margin:0;"></iframe>
              <!-- <baidu-map
                id="map"
                style="width: 390px; height: 300px"
                :center="center"
                :zoom="zoom"
                :scroll-wheel-zoom="true"
                @ready="initMap"
              /> -->
            </div>
          </div>
          <div v-if="orderStatus != 1">
            <el-empty v-if="isNull(form.orderLogistics) || isNull(logistics.records)" :image-size="200" />
          </div>
        </template>
        <template v-if="form.orderInfo.orderType == 2 && form.pinStatus == 1">
          <scrabbleDoughCountdown />
        </template>
      </div>
    </div>
    <template v-if="form.orderInfo.orderType == 2 && form.pinStatus == 2">
      <scrabbleDough />
    </template>
    <div class="box">
      <div style="margin: 10px">
        <div class="flex-table borderMain">
          <div style="flex: 1.5">{{ (form.tenderType == '' || form.tenderType == '0') ? '商品名称' : '招标单名称' }}</div>
          <div v-if="form.tenderType != '' && form.tenderType != '0'">招标项目</div>
          <div v-if="form.tenderType == '' || form.tenderType == '0'">数量</div>
          <div v-if="form.tenderType == '' || form.tenderType == '0'">单价（元）</div>
          <div>{{ (form.tenderType == '' || form.tenderType == '0') ? '合计（元）' : '中标金额（元）' }}</div>
          <div v-if="form.tenderType == '' || form.tenderType == '0'">物流单号</div>
        </div>
        <div class="flex-table" v-if="form.tenderType != '' && form.tenderType != '0'">
          <div class="name-box">
            <div>
              <img src="" @error="dealErr($event)" />
            </div>
            <div class="txt">{{ form.tenderName }}</div>
          </div>
          <div>{{ form.projectName }}</div>
          <div style="color: #ff7826">{{ this.skuAmount }}</div>
          <!-- <div style="flex: 1.5;display: flex; justify-content: center;">
          <div style="float: left; position:absolute;left: 20px;" :class="isWidthEnough ? 'name-box' : 'name-box-small'">
            <div>
              <img src="" @error="dealErr($event)" />
            </div>
          </div>
          <div style="height: 100px;display: flex; flex-direction: column; justify-content: center;">
            <div style="line-height:50px;"></div>
            <div></div>
          </div>
          </div>  -->
        </div>
        <div v-for="(i, d) in form.shopDTOList" :key="d">
          <div class="bord-bottom">{{ i.supplierName }}</div>
          <div v-if="form.tenderType == '' || form.tenderType == '0'">
            <div v-for="(v, k) in i.goodsDTOList" :key="k" class="flex-table">
              <div :class="isWidthEnough ? 'name-box' : 'name-box-small'">
                <div>
                  <img :src="v.fileUrl" @error="dealErr($event)" />
                </div>
                <div class="txt">{{ v.skuName }}</div>
              </div>
              <div>{{ v.skuNum }}</div>
              <div>{{ v.skuPriceShow ? v.skuPriceShow : '' }}</div>
              <div>{{ v.skuPriceShow ? singleGoodsTotal(v.skuNum, v.skuPriceShow) : '' }}</div>
              <div class="waterClass">
                <div v-if="k == 0" class="waterItem" :style="{ top: 62 * (i.goodsDTOList.length - 1) + 'px' }">
                  <div v-for="item in form.orderLogistics" :key="item.id">
                    {{ item.logisticsCode }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="title">给卖家留言 :</div>
            <div>
              <el-input v-model="form.orderInfo.orderLeaveWord" placeholder="选填，字数不可多于500" type="textarea"
                maxlength="500" show-word-limit rows="7" readonly></el-input>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="box">
        <div class="title">给卖家留言 :</div>
        <div>
          <el-input
            v-model="form.orderInfo.orderLeaveWord"
            placeholder="选填，字数不可多于500"
            type="textarea"
            maxlength="500"
            show-word-limit
            rows="7"
            readonly
          ></el-input>
        </div>
      </div> -->
      <div class="total">
        <span>商品金额(元):<span class="price">&nbsp;{{ this.skuAmount }}</span>&nbsp;&nbsp;/运费金额(元):<span
            class="price">&nbsp;{{
      this.freight }}</span>
          <span v-if="form.orderInfo.isUseVoucher">
            &nbsp;&nbsp;/{{ form.orderInfo.voucherName }}抵扣金额(元):<span class="price">&nbsp;{{ form.payVoucherAmount }}</span>
          </span>
          <template v-if="form.orderInfo.voucherType === 2">
            /手续费(元):<span class="price">&nbsp;{{ form.handlingFee }}</span>
          </template>
          &nbsp;&nbsp;/实付款(元):<span class="price">&nbsp;{{ form.actualPayment }}</span></span>
      </div>
      <div class="box" v-if="form.orderInfo.isInvoice == 1">
        <page-top-title name="发票信息"></page-top-title>
        <el-form :model="form.orderInvoice" label-width="80px" :inline="false">
          <el-form-item label="发票要求">
            <el-select disabled v-model="form.orderInvoice.invoiceType" placeholder="请选择发票要求" clearable filterable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-row class="supplymode-menu" type="flex">
            <el-col class="active">{{
      form.orderInvoice.invoiceClass == 2 ? "企业" : "个人"
    }}</el-col>
          </el-row>
          <div style="height: 10px"></div>
          <div>
            <el-form :model="form.orderInvoice" label-width="90px" :inline="true" disabled>
              <el-form-item label="发票抬头 : ">
                <div class="fp-input">{{ form.orderInvoice.invoiceTitle }}</div>
              </el-form-item>
              <el-form-item label="税号 : " v-if="form.orderInvoice.invoiceClass == 2">
                <div class="fp-input">
                  {{ form.orderInvoice.invoiceRateCode }}
                </div>
              </el-form-item>
              <el-form-item label="企业地址 : " v-if="form.orderInvoice.invoiceClass == 2">
                <div class="fp-input">
                  {{ form.orderInvoice.companyAddress }}
                </div>
              </el-form-item>
              <el-form-item label="企业电话 : " v-if="form.orderInvoice.invoiceClass == 2">
                <div class="fp-input">{{ form.orderInvoice.companyPhone }}</div>
              </el-form-item>
              <el-form-item label="开户银行 : " v-if="form.orderInvoice.invoiceClass == 2">
                <div class="fp-input">{{ form.orderInvoice.bankName }}</div>
              </el-form-item>
              <el-form-item label="银行账户 : " v-if="form.orderInvoice.invoiceClass == 2">
                <div class="fp-input">{{ form.orderInvoice.bankAccount }}</div>
              </el-form-item>
            </el-form>
          </div>
        </el-form>
      </div>
      <div class="box" v-if="form.orderInfo.isInvoice == 2">
        <page-top-title name="发票信息"></page-top-title>
        <span style="color: #606266">发票要求: </span>无需发票
      </div>
    </div>
    <page-top-title name="评价"></page-top-title>
    <div class="evaluate">
      <el-row :gutter="180" style="padding:0px 0px 20px">
        <el-col :span="24">
          <div class="block-title">
            采购商评价
            <span style="margin-left:20px"> <img :src="`${require(`@/assets/images/order/eval.png`)}`" />
              综合得分：{{ buyEvalInfo ? buyEvalInfo.comprehensiveScore : '' }}</span>
          </div>
          <!-- <div class="evaluate-top">
            <span
              ><i class="iconfont icon-pingjia1 r"></i
              >{{ getEvaluate(1).score | escape(scoreDict) }}</span
            >
            <span>{{ getEvaluate(1).updateTime }}</span>
          </div> -->

          <div class="evaluate-content">
            <el-row>
              <el-col :span="6" style="text-align: center;line-height: 50px">

                <div style="white-space: nowrap;">
                  发货时效：
                  <el-rate :value="buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.deliveryTimeLimit / 100 : 0"
                    disabled show-score text-color="#ff9900" score-template="" style="display: inline-block;">
                  </el-rate>
                  {{ buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.deliveryTimeLimit : '' }}
                </div>
                <div style="white-space: nowrap;">
                  物流评价：
                  <el-rate :value="buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.logisticsEvaluation / 100 : 0"
                    disabled show-score text-color="#ff9900" score-template="" style="display: inline-block;">
                  </el-rate>
                  {{ buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.logisticsEvaluation : '' }}
                </div>
                <div style="white-space: nowrap;">
                  商品评价：
                  <el-rate :value="buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.reviews / 100 : 0" disabled
                    show-score text-color="#ff9900" score-template="" style="display: inline-block;">
                  </el-rate>
                  {{ buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.reviews : '' }}
                </div>
                <div style="white-space: nowrap;">
                  服务评价：
                  <el-rate :value="buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.serviceEvaluation / 100 : 0"
                    disabled show-score text-color="#ff9900" score-template="" style="display: inline-block;">
                  </el-rate>
                  {{ buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.serviceEvaluation : '' }}
                </div>
                <div style="white-space: nowrap;">
                  售后评价：
                  <el-rate
                    :value="buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.afterSalesEvaluation / 100 : 0"
                    disabled show-score text-color="#ff9900" score-template="" style="display: inline-block;">
                  </el-rate>
                  {{ buyEvalInfo && buyEvalInfo.buyerScore ? buyEvalInfo.buyerScore.afterSalesEvaluation : '' }}
                </div>
              </el-col>
              <el-col :span="18">
                <el-row>
                  <el-col style="padding:8px 0px">
                    <el-input :disabled="true" type="textarea" :rows="12" maxlength="200" show-word-limit
                      v-model="buyEvalInfo.content"></el-input>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col style="text-align: right;">
                    {{ buyEvalInfo ? buyEvalInfo.createTime : '' }}
                  </el-col>
                </el-row>

              </el-col>
            </el-row>
          </div>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="block-title">
            供应商评价
            <span style="margin-left:20px"> <img :src="`${require(`@/assets/images/order/eval.png`)}`" />
              综合得分：{{ supplierEvalInfo ? supplierEvalInfo.supplierComprehensiveScore : '' }}</span>
          </div>
          <!-- <div class="evaluate-top">
            <span
              ><i class="iconfont icon-pingjia1 r"></i
              >{{ getEvaluate(1).score | escape(scoreDict) }}</span
            >
            <span>{{ getEvaluate(1).updateTime }}</span>
          </div> -->

          <div class="evaluate-content">
            <el-row>
              <el-col :span="6" style="text-align: center;line-height: 50px">

                <div style="white-space: nowrap;">
                  收货时效：
                  <el-rate
                    :value="supplierEvalInfo && supplierEvalInfo.supplierScore ? supplierEvalInfo.supplierScore.receivingTimeLimit / 100 : 0"
                    disabled text-color="#ff9900" score-template="" style="display: inline-block;">
                  </el-rate>
                  {{ supplierEvalInfo && supplierEvalInfo.supplierScore ? supplierEvalInfo.supplierScore.receivingTimeLimit : '' }}
                </div>
                <div style="white-space: nowrap;">
                  退货反馈：
                  <el-rate
                    :value="supplierEvalInfo && supplierEvalInfo.supplierScore ? supplierEvalInfo.supplierScore.returnFeedback / 100 : 0"
                    disabled show-score text-color="#ff9900" score-template="" style="display: inline-block;">
                  </el-rate>
                  {{ supplierEvalInfo && supplierEvalInfo.supplierScore ? supplierEvalInfo.supplierScore.returnFeedback : '' }}
                </div>

              </el-col>
              <el-col :span="18">
                <el-row>
                  <el-col style="padding:8px 0px">
                    <el-input v-if="supplierEvalInfo" :disabled="true" type="textarea" :rows="12" maxlength="200"
                      show-word-limit v-model="supplierEvalInfo.content"></el-input>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col style="text-align: right;">
                    {{ supplierEvalInfo ? supplierEvalInfo.createTime : '' }}
                  </el-col>
                </el-row>

              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <el-dialog
      title="地图"
      :visible.sync="mapShow"
      width="70%"
      append-to-body
      :close-on-click-modal="false"
    >
      <div style="width: 100%; height: 100%">
        <baidu-map
          id="map"
          ref="baiduMap"
          style="width: 1445px; height: 800px"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
          @ready="initMap"
        />
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { defaultForm } from "./default";
import { detail, logisticsDetail } from "@/api/purchaser/myBiddingOrder";
import { formatTime } from "@/utils/util";
import { validatenull } from "@/utils/validate";
export default {
  data() {
    return {
      identity:1,
      dialogLogisticVisible: false,
      orderInfo: {},
      initMaps: '',
      formRow: {},
      payTimeOut: "",
      activeName: "",
      scoreDict: [
        {
          value: "1",
          label: "好评",
        },
        {
          value: "2",
          label: "中评",
        },
        {
          value: "3",
          label: "差评",
        },
      ],
      form: { ...defaultForm },
      options: [
        { value: 2, label: "无需发票" },
        { value: 1, label: "增值税普通发票" },
        { value: 0, label: "增值税专用发票" },
      ],
      isWidthEnough: true,
      orderStatus: "",
      txtArray: [],
      orderPayMethod: {
        1: "银行卡支付",
        2: "支付宝",
        3: "微信",
        4: "对公转账",
        5: "B2B支付",
        6: "账期",
        7: "农行支付",
      },
      ok: true,
      lastTime: 0,
      settimer: null,
      logisticsCode: null,
      logistics: [],
      mapShow: false,
      center: { lng: 116.404, lat: 39.915 },
      zoom: 10,
      buyEvalInfo: {},
      supplierEvalInfo: {},
      typeMap: {
        1: '消费券',
        2: '账期',
        3: '智慧工地硬件消费券',
        4: '邀请消费券'
      }
    };
  },
  components: {},
  computed: {
    singleGoodsTotal() {
      return function (num, price) {
        if (Number(num) !== NaN && Number(price) !== NaN) {
          let res = parseInt(num) * parseFloat(price);
          return res.toFixed(2);
        } else {
          return 0.0;
        }
      };
    },
    skuAmount() {
      let result = 0;
      this.form.orderGoods.forEach((item) => {
        if (
          item.totalPriceShow !== undefined &&
          item.totalPriceShow !== "" &&
          item.totalPriceShow !== null
        ) {
          let totalPriceShow = parseFloat(item.totalPriceShow);
          result += totalPriceShow;
        }
      });
      return result.toFixed(2);
    },
    freight() {
      if (this.form.freight !== "" && this.form.freight !== null) {
        return this.form.freight.toFixed(2);
      } else {
        return 0;
      }
    },
    actualPayment() {
      let skuAmount = parseFloat(this.skuAmount);
      let freight = parseFloat(this.freight);
      if (this.form.orderInfo.isUseVoucher) {
        return (skuAmount + freight - this.form.payVoucherAmount).toFixed(2);
      }
      return (skuAmount + freight).toFixed(2);
    },
    // discountAmount() {
    //   let amount = this.formRow.discountPrice / 100
    //   return (isNaN(amount) ? 0 : amount).toFixed(2);
    // },
    // afterDiscountAmount() {
    //   let amount = this.formRow.payPrice / 100
    //   return (isNaN(amount) ? 0 : amount).toFixed(2);
    // },
  },
  created() { },
  mounted() {
    this.identity=this.$route.query.identity
    if (document.body.offsetWidth < 1450) {
      this.isWidthEnough = false;
    }
    this.detail();
  },
  beforeDestroy() {
    clearInterval(this.settimer);
  },
  methods: {
    initMap(x, y) {
      // console.log(BMap, map1);
      this.center.lng = x;
      this.center.lat = y;
      // this.zoom = 15;
      // 设置地图的中心点和缩放级别
      const point = new BMap.Point(x, y);
      const map = new BMap.Map("map");
      map.centerAndZoom(point, 15);

      // 添加起点和终点标注
      if (!validatenull(this.logistics.records)) {
        let arr = this.logistics.records?.filter(
          (item) => item.areaCenter != ""
        );
        let endx = arr[arr.length - 1].areaCenter.split(",")[0];
        let endy = arr[arr.length - 1].areaCenter.split(",")[1];
        const startMarker = new BMap.Marker(new BMap.Point(x, y));
        const endMarker = new BMap.Marker(new BMap.Point(endx, endy));
        map.addOverlay(startMarker);
        map.addOverlay(endMarker);

        // 绘制路线
        const driving = new BMap.DrivingRoute(map, {
          renderOptions: { map: map, autoViewport: true },
        });
        driving.search(new BMap.Point(endx, endy), new BMap.Point(x, y));
      }
    },
    countDown() {
      this.settimer = setInterval(() => {
        this.lastTime = this.lastTime - 1;
        if (this.ok) {
          console.log(this.lastTime);
          if (this.lastTime <= 0) {
            this.ok = false;
            this.detail();
            // this.refundDetailVO.status = 1;
          } else {
            this.ok = true;
            let ftime = formatTime(this.lastTime);
            this.payTimeOut =
              ftime.days +
              "天" +
              ftime.hours +
              "小时" +
              ftime.mins +
              "分" +
              ftime.secs +
              "秒";
          }
        }
      }, 1000);
    },
    dealErr(e) {
      e.target.src = require("@/assets/error.svg");
    },
    formattingData(arr, group_key) {
      let map = {};
      let res = [];

      for (let i = 0; i < arr.length; i++) {
        let ai = arr[i];
        if (!map[ai[group_key]]) {
          map[ai[group_key]] = [ai];
        } else {
          map[ai[group_key]].push(ai);
        }
      }
      Object.keys(map).forEach((key) => {
        res.push({
          [group_key]: key,
          goodsDTOList: map[key],
        });
      });
      // res[0].goodsDTOList.push({
      //   skuName: "asdsadasd"
      // })

      return res;
    },
    pay(row) {
      this.$store.commit("SET_PARAMS", {
        txtArray: [row.createTime, "未支付"],
        shopName: row.supplierName, // 供应商名称
        orderCode: row.orderCode,
        price: row.price,
        orderSource: row.orderSource,
      });
      this.$router.push({
        path: "/commons/sure-pay/index",
      });
    },
    detail() {
      detail({ id: this.$route.query.id }).then((res) => {
        if (res.code != 200) {
          // this.$message.warning(res.msg);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1200);
        }
        this.form = Object.assign({ ...defaultForm }, res.data);
        debugger
        this.form.shopDTOList = [];
        this.logisticsCode = this.form.orderLogistics[0]?.logisticsCode;
        this.logisticsType(this.logisticsCode);
        this.form.shopDTOList = this.formattingData(
          this.form.orderGoods,
          "supplierName"
        );
        this.lastTime = res.data.payTimeOut;
        let ftime = formatTime(res.data.payTimeOut || 0);
        this.payTimeOut =
          ftime.days +
          "天" +
          ftime.hours +
          "小时" +
          ftime.mins +
          "分" +
          ftime.secs +
          "秒";
        this.countDown();
        this.orderInfo = res.data.orderInfo
        let status = +res.data.orderInfo.status;
        this.formRow = res.data.orderInfo;
        if (status == 0 || status == 1) {
          this.orderStatus = 1;
          this.formRow.price = res.data.orderInfo.payPrice / 100;
        } else if (status == 2) {
          this.orderStatus = 2;
        } else if (status == 3) {
          this.orderStatus = 3;
        } else if (status == 4) {
          this.orderStatus = 4;
        }
        if (res.data.orderInfo.evaluateDate) {
          this.orderStatus = 5;
        }
        this.txtArray = [
          res.data.orderInfo.createTime,
          res.data.orderInfo.payDate,
          res.data.orderInfo.deliveryDate,
          res.data.orderInfo.receiptDate,
          res.data.orderInfo.evaluateDate,
        ];
        console.log('---------------------------')
        console.log(res.data.orderEvaluate)
        if (res.data.orderEvaluate) {
          for (var i in res.data.orderEvaluate) {
            //(1采购方2供应商)
            if (res.data.orderEvaluate[i].evalSource == 1) {
              this.buyEvalInfo = res.data.orderEvaluate[i]
            }
            if (res.data.orderEvaluate[i].evalSource == 2) {

              this.supplierEvalInfo = res.data.orderEvaluate[i]
              console.log('---------------------------')
              console.log(this.supplierEvalInfo)
            }
          }
        }
      });
    },
    getEvaluate(type) {
      let oie = JSON.parse(JSON.stringify(this.form.orderEvaluate));
      if (oie.length == 0) {
        return [];
      }
      let arr = oie.filter((item) => {
        return item.evalSource == type;
      });
      return arr[0] ? arr[0] : [];
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    logisticsType(tab) {
      logisticsDetail(tab).then((res) => {
        this.logistics = res.data;
        let x = res.data.records[0]?.areaCenter.split(",")[0];
        let y = res.data.records[0]?.areaCenter.split(",")[1];
        this.center.lng = x;
        this.center.lat = y;
        this.initMaps = logistics.trailUrl
        // this.initMap(x, y);
      });
      this.logisticsCode = tab;
    },
    isNull(row) {
      if (validatenull(row)) {
        return true;
      }
    },
    openMapDia() { },
  },
};
</script>
<style scoped lang='scss'>
.box {
  box-sizing: border-box;
  padding: 15px 0px;

  .fp-input {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #5f5f5f;
    cursor: not-allowed;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    display: inline-block;
    height: 40px;
    line-height: 38px;
    width: 100%;
    min-width: 330px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow-y: auto;
  }

  .flex-table {
    margin: 10px -40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #606266;
    position: relative;

    .waterClass {
      position: relative;
      margin-top: -20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .waterItem {
        font-size: 13px;
        position: absolute;
      }
    }

    div {
      // width: 100%;
      flex: 1;
      text-align: center;
    }

    .name-box {
      box-sizing: border-box;
      // padding: 0 20px;
      line-height: 20px;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 13px;
      flex: 1.5;

      .txt {
        text-align: left;
      }

      img {
        width: 100px;
        height: 100px;
        margin-right: 5px;
      }
    }

    .name-box-small {
      box-sizing: border-box;
      padding: 0 20px;
      line-height: 20px;
      text-align: left;
      font-size: 13px;

      .txt {
        text-align: left;
      }

      img {
        width: 150px;
        height: 80px;
        margin-bottom: 5px;
      }
    }
  }

  .borderMain {
    margin: 10px -40px;
    border-top: 1px solid #f2f6fc;
    border-bottom: 8px solid #f2f6fc;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .bord-bottom {
    margin: 0 -40px;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    border-bottom: 2px solid #f2f6fc;
  }

  .evaluate {
    overflow: hidden;
  }

  .block-title {
    margin-bottom: 10px;
    font-size: 15px;
    color: #333;
    font-weight: bold;
  }

  .evaluate-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .r {
      color: red;
      font-size: 30px;
      margin-right: 5px;
      vertical-align: -3px;
    }
  }

  .evaluate-content {
    box-sizing: border-box;
    padding: 15px;
    border: 1px solid $border-color;
    font-size: 14px;
    color: #606266;
  }

  .supplymode-menu {
    color: #606266;
    text-align: center;
    margin-bottom: 10px;

    .el-col {
      height: 31px;
      line-height: 31px;
      width: 110px;
      margin-right: 20px;
      cursor: pointer;

      i {
        font-size: 20px;
        color: $blue;
        vertical-align: -0.1em;
      }
    }

    .el-col:nth-child(1),
    .el-col:nth-child(2) {
      position: relative;
      line-height: 28px;
      border: 2px solid #ccc;

      &.active {
        border: 2px solid $blue;
        color: $blue;

        &::before {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          border: 7.5px solid $blue;
          border-top-color: transparent;
          border-left-color: transparent;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          border: none;
          width: 8px;
          height: 7px;
          background: url(./selected.svg) center center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    color: #303133;
    font-size: 14px;
    margin: 12px;
  }

  .backBox {
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(235, 120, 12, 0.2);
    margin: 10px 0;
    width: 400px;
    height: 160px;
    text-align: right;

    .flex-end {
      display: flex;
      justify-content: flex-end;

      .orange {
        display: block;
        color: #e65a09;
        font-size: 22px;
      }
    }

    .title {
      color: #606266;
      font-size: 14px;
      margin: 6px;
    }
  }

  .intrudct {
    position: relative;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid #409eff;
    background-color: rgba(184, 213, 240, 0.1);

    .post {
      position: absolute;
      right: 10px;
      bottom: 5px;
      text-align: center;

      .orange {
        color: #e6a23c;
        font-size: 26px;
      }
    }
  }

  .textCss {
    flex-shrink: 0;
    width: 30%;
    color: #909399;
    font-size: 14px;
    word-break: break-all;
  }

  .right {
    flex-shrink: 0;
    margin-left: 3%;
    width: 65%;
    word-break: break-all;
    border: 1px solid #409eff;

    .col_5 {
      color: #606266;
      font-size: 14px;
      padding: 10px 20px;

      .title {
        color: #909399;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
      }

      .sub-title {
        color: #909399;
      }
    }
  }

  .el-input {
    width: 330px;
  }

  .el-select {
    width: 420px;
  }
}

.total {
  font-size: 16px;
  padding: 10px 0 20px;
  text-align: right;
  color: #666;
  margin-bottom: 10px;

  .price {
    color: #0079fe;
  }
}

.tabActive {
  border-bottom: 1px solid #0079fe;
  color: #0079fe;
}
</style>
